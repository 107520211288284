import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Card from 'reactstrap/lib/Card';

import {
  PricingCreativeConfigDefaultProps,
  PricingCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { PRICING_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';

import { getNonMSRPDefaultLabel, isNonMSRPMake, isOemUrlWhitelisted } from './data-helper';
import './pricing-site-served-ad.scss';
import './pricing-horizontal-site-served-ad.scss';

export function PricingHorizontalSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  vehicle,
  mobile,
  position,
  cardClassName,
  wrapperClass,
  ivtClassName,
  showBorder,
  ads10380Recipe,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    creativeConfigData: {
      photoDisclaimer,
      photo,
      logo,
      headline,
      msrp: msrpConfig,
      disclaimerCopy,
      lineItemId,
      siteServedCreativeId,
      linkDisplayUrl,
      cta,
      isConquest,
      target,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, cta);
    },
    [adLoadTrackingListener, cta]
  );

  const msrp = msrpConfig?.trim();
  const make = get(vehicle, 'make.niceName', '');

  const isNonMsrpOem = isNonMSRPMake(make);
  const isWhiteListedUrl = isOemUrlWhitelisted(linkDisplayUrl);

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';

  const noMSRPLabelDisplay = msrp || getNonMSRPDefaultLabel(make);
  const noMSRPLabel = !!noMSRPLabelDisplay && (
    <div className="label-container mx-0_5 display-3">{noMSRPLabelDisplay}</div>
  );
  const labelComponent =
    (isNonMsrpOem && noMSRPLabel) ||
    (msrp && (
      <div className="label-container d-md-flex align-items-end">
        <ContentFragment classes="heading-4 text-cool-gray-10 mr-0_25" componentToUse="span">
          {msrp}
        </ContentFragment>
        <span className="small text-gray-darker d-block text-cool-gray-40">Starting MSRP</span>
      </div>
    ));

  const nativeStyle = PRICING_AD.NATIVE_STYLE;
  const isAds10380Enabled = ['chal1', 'chal2'].includes(ads10380Recipe);

  return (
    <div
      className={classnames(
        'pricing-site-served-ad-unit pricing-horizontal rounded w-100',
        wrapperClass,
        ivtClassName,
        {
          'is-whitelisted-oem-url': isWhiteListedUrl,
          'show-border': showBorder,
          'gradient-bg': ads10380Recipe === 'chal2',
        }
      )}
      style={{ overflow: 'hidden' }}
    >
      <Card
        className={cardClassName}
        data-tracking-parent={nativeStyle}
        data-viewability={`${nativeStyle}-${position}`}
        innerRef={adRef}
      >
        <DfpTracking
          lineItemId={lineItemId}
          siteServedCreativeId={siteServedCreativeId}
          mobile={mobile}
          nativeStyle={nativeStyle}
          position={position}
          slotRenderEndListener={loadTrackingListener}
          siteServedAdsUpdater={siteServedAdsUpdater}
        />
        {adTrackers}
        <a
          href={ctaURL}
          target={ctaTarget}
          rel="noopener noreferrer"
          onClick={adClickTrackingListener}
          className={classnames('site-served-ad-link no-focus h-100 text-decoration-none overflow-hidden', {
            'is-ads-10380-enabled pb-0_5': isAds10380Enabled,
            'py-0_5': !isAds10380Enabled,
          })}
        >
          <AdLabel />
          {isConquest ? (
            <ConquestRibbon style={{ position: 'absolute', left: 0, top: 8, height: '20px' }} />
          ) : (
            <img
              className="logo"
              style={{ position: 'absolute', maxHeight: '24px' }}
              alt=""
              src={logo}
              loading="lazy"
            />
          )}
          <div className={classnames('img-wrapper', { 'mt-1': mobile, 'pl-0_75': isAds10380Enabled })}>
            <img
              className={classnames('core-img img-fluid d-block mx-auto w-100', {
                'has-img-disclaimer': !!photoDisclaimer,
                background: !photo,
              })}
              style={{ maxHeight: '140px', objectFit: 'contain' }}
              src={photo}
              alt=""
              loading="lazy"
            />
          </div>
          {!isAds10380Enabled ? (
            <Fragment>
              <div
                className={classnames('ymm-info d-flex flex-column justify-content-center px-0_75', {
                  'my-auto mt-md-auto mb-0_5': !mobile,
                })}
              >
                {headline && (
                  <ContentFragment classes="heading-6 mb-0_5 mb-md-0 text-cool-gray-40">{headline}</ContentFragment>
                )}
                {labelComponent}
              </div>
              <div className="cta-container d-flex px-0_75 mb-auto">
                <div className="mr-1 px-1 cta btn btn-sm btn-outline-blue-50 text-transform-none size-16">
                  <CtaButtonText text={cta} />
                </div>
                <div className="text-blue-50 font-weight-medium d-flex align-items-center">{linkDisplayUrl}</div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className={classnames('ymm-info d-flex flex-column justify-content-center pr-0_75', {
                  'my-auto mt-md-auto mb-0_5': !mobile,
                })}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {(isNonMsrpOem && noMSRPLabel) ||
                    (msrp && (
                      <div className="label-container text-center">
                        <ContentFragment classes="heading-3 text-cool-gray-10 " componentToUse="span">
                          {msrp}
                        </ContentFragment>
                        <div className="small text-gray-darker d-block text-cool-gray-40">Starting MSRP</div>
                      </div>
                    ))}
                  {headline && (
                    <ContentFragment classes="heading-6 mb-0_5 mb-md-0 text-cool-gray-40">{headline}</ContentFragment>
                  )}
                </div>
              </div>
              <div className="cta-container d-flex mx-0_75 mb-auto bg-blue-90 pos-r">
                <div
                  className="mr-0_75 px-1 cta btn btn-sm btn-blue-50 text-transform-none size-16 py-0_25 pos-r"
                  style={{
                    flex: '2 1 auto',
                    borderTopRightRadius: '1rem',
                    borderBottomRightRadius: '1rem',
                  }}
                >
                  <CtaButtonText text={cta} />
                </div>
                <div
                  className="text-blue-50 font-weight-medium d-flex align-items-center size-12 pos-r"
                  style={{
                    flex: '1 0 auto',
                  }}
                >
                  {linkDisplayUrl}
                </div>
              </div>
            </Fragment>
          )}
          {photoDisclaimer && <div className="pl-0_75 mt-0_5 text-gray-darker size-10">{photoDisclaimer}</div>}
        </a>
        {disclaimerCopy && <Disclaimer disclaimerText={disclaimerCopy} buttonStyle={{ bottom: 0 }} />}
      </Card>
    </div>
  );
}

PricingHorizontalSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: PricingCreativeConfigPropTypes,
  cardClassName: PropTypes.string,
  wrapperClass: PropTypes.string,
  showBorder: PropTypes.bool,
  ads10380Recipe: PropTypes.string,
};

PricingHorizontalSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: PricingCreativeConfigDefaultProps,
  cardClassName: null,
  wrapperClass: null,
  showBorder: false,
  ads10380Recipe: '',
};

export const PricingHorizontalSiteServedAd = siteServedAdTrackingWrapper(PricingHorizontalSiteServedAdUI);
